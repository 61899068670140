import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2022-12-05-harnett/body-landing"

const HarnettView = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="STIs Are Running Rampant—Are You Up to Date on the Latest Guidelines?"
				videoDisplayTitle="STIs Are Running Rampant—Are You Up to Date on the Latest Guidelines?"
				vimeoLink="https://player.vimeo.com/video/779347941?h=c3c28be92c"
				placeholderImage="2022-12-05-harnett-webinar-replay.jpg"
				localStorageID="2022-12-05-harnett-webinar-replay"
				pageSlug="/webinar-replay/2022-12-05-harnett/"
			/>
			<BodyLanding pageName="download" localStorageID="2022-12-05-harnett-webinar-replay" />
		</React.Fragment>
	)
}

export default HarnettView

export const Head = () => (
  <Seo
	title="Webinar Replay: STIs Are Running Rampant—Are You Up to Date on the Latest Guidelines?"
	description="STIs Are Running Rampant—Are You Up to Date on the Latest Guidelines?"
	image="/meta/webinar-harnett-2022-12-05.jpg"
  />
)
